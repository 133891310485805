<template>
  <v-card :min-height="cardHeight">
    <v-card-title>Billable hours by month</v-card-title>
    <v-row>
      <v-col lg="2" class="mx-4">
        <p class="text-body font-weight-medium primary--text">
          Select users:
        </p></v-col
      >
      <v-col lg="8" sm="12">
        <v-select
          :items="users"
          v-model="us"
          dense
          outlined
          item-text="name"
          return-object
          multiple
          @change="updateChartData"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="2" sm="12" class="mx-4">
        <p class="text-body font-weight-medium primary--text">
          Select time type:
        </p></v-col
      >
      <v-col lg="4" sm="12">
        <v-select
          :items="timeTypes"
          v-model="selectedType"
          dense
          outlined
          @change="updateChartData"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <GChart
          :settings="{ packages: ['line'] }"
          :data="chartData"
          :options="chartOptions"
          :createChart="(el, google) => new google.charts.Line(el)"
          @ready="onChartReady"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <template v-for="item in chartData.slice(0)">
                  <th class="text-left" :key="item[0]">{{ item[0] }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="(user, i) in chartData[0].slice(1)">
                <tr :key="user">
                  <template v-for="item in chartData.slice(0)">
                    <td :key="item[0]">{{ item[i+1] }}</td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import { mapGetters } from "vuex";
import { GChart } from "vue-google-charts";
export default {
  name: "YearBillableHoursUserCard",
  components: { GChart },
  props: {
    cardHeight: String,
    year: String,
  },
  data: () => ({
    chartsLib: null,
    tableData: [450, 400, 0],
    us: [],
    timeTypes: ["Normal", "Sickness", "Vacation", "VAB", "Parental leave"],
    selectedType: "Normal",
    chartData: [
      ["User", "Nan"],
      ["Jan.", null],
      ["Feb.", null],
      ["Mar.", null],
      ["Apr.", null],
      ["May", null],
      ["June", null],
      ["July", null],
      ["Aug.", null],
      ["Sep.", null],
      ["Oct.", null],
      ["Nov.", null],
      ["Dec.", null],
    ],
  }),
  computed: {
    chartOptions() {
      if (!this.chartsLib) return null;
      return this.chartsLib.charts.Line.convertOptions({
        chart: {
          title: "Billable Hours",
          // subtitle: "A Subtitle",
        },
      });
    },
    ...mapGetters({
      users: "users/users",
      timeReports: "timeReports/timeReports",
    }),
  },
  watch: {
    year: function () {
      this.updateChartData()
    }
  }, 
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
    updateChartData() {
      let chartData = [
        ["User"],
        ["Jan."],
        ["Feb."],
        ["Mar."],
        ["Apr."],
        ["May"],
        ["June"],
        ["July"],
        ["Aug."],
        ["Sep."],
        ["Oct."],
        ["Nov."],
        ["Dec."],
      ];

      var trs = this.timeReports;
      let trsNormal = []

      let userExist = function (userList, id) {
        for (const u of userList) {
          if (u.id === id) {
            return true;
          }
        }
      };
      if (this.us.length == 0){
        chartData[0][1] = "nan"
        for (const x of Array(12).keys()) {
          chartData[x + 1][1] = null;
        }
        this.chartData = chartData
        return
      }

      for (const tr of trs) {
        if (
          tr.type === this.selectedType &&
          tr.month.split("-")[0] == this.year &&
          userExist(this.us, tr.user.id)
        ) {
          trsNormal.push(tr);
        }
      }
      for (let i = 0; i < this.us.length; i++) {
        chartData[0][i + 1] = this.us[i].name;
        for (const x of Array(12).keys()) {
          chartData[x + 1][i + 1] = 0;
        }
        for (const tr of trsNormal) {
          if (tr.user.id === this.us[i].id) {
            let monthInt = parseInt(tr.month.split("-")[1]);
            // Add the hours to the correct month, and add the existing hours if there are any
            // To support multiple time reports for the same month
            chartData[monthInt][i + 1] = parseInt(tr.hours, 10) + chartData[monthInt][i + 1];
          }
        }
      }
      this.chartData = chartData;
    },
  },
};
</script>